import { Navigate, RouteObject } from "react-router";
import { LoginPage } from "../pages/Login/Login";
import { SignUp } from "../pages/Sign-up/Signup";

export const publicRoutes: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignUp/>,
  
  },
  {
    path: "/*",
    element: <Navigate to="/login" />,
  },
];
