import "./top-nav.scss";

import { useState } from "react";

import { useLocation } from "react-router-dom";
import sidebarNav from "./side-bar";

const TopNav = ({ name, toggleSidebar }: any) => {
  const location = useLocation();

  const handleSidebarClick = () => {
    const element: any = document.querySelector(".selector");

    if (element.classList.contains("bx-chevron-left")) {
      element.classList.replace("bx-chevron-left", "bx-chevron-right");
    } else {
      element.classList.replace("bx-chevron-right", "bx-chevron-left");
    }

    toggleSidebar();
  };

  const curPath = location.pathname.split("/")[1];

  const headerView = (header: string) => {
    let currentHeader = "";
    if (header === "") return "الرئيسية";
    if (sidebarNav.length > 0) {
      currentHeader = sidebarNav?.find(
        (nav: {
          link: string;
          section: string;
          icon: JSX.Element;
          text: string;
        }) => nav.section === header
      )?.text ?? "";
    }

    currentHeader = currentHeader.charAt(0).toUpperCase() + currentHeader.slice(1).toLowerCase();
    currentHeader = currentHeader.replace("-", " ");

    return currentHeader;
  };
  return (
    <div className="topnav">

      <div className="sidebar-toggle" onClick={handleSidebarClick}>
        <i className="selector bx bx-chevron-left"></i>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h2 className="header-label">{headerView(curPath)}</h2>
      </div>
    </div>
  );
};

export default TopNav;
