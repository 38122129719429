import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import toast, { Toaster } from "react-hot-toast";
import { getAttendancesAPI } from "./api/get-attendances";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Attendance } from "./types/get-attendances-response.type";

export const AttendancesPage = () => {
  const { mutateAsync, data, isLoading } = useMutation(getAttendancesAPI);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  useEffect(() => {
    toast.promise(
      mutateAsync({
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      }),
      {
        loading: "جاري التحميل",
        success: "تم التحميل بنجاح",
        error: "حدث خطأ أثناء التحميل",
      }
    );
  }, [paginationModel]);
  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <Toaster
        containerClassName="toaster-wrapper"
        position="top-center"
        containerStyle={{
          zIndex: "999999 !important",
        }}
        toastOptions={{
          className: "react-hot-toast",
        }}
      />
      <Box sx={{ height: "80%", padding: "0 0 0 5%", width: "100%" }}>
        <DataGrid
          sx={{
            height: "80vh",
          }}
          rows={data?.data?.result?.list ?? []}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          getRowId={(row: Attendance) => row.perId}
          pageSizeOptions={[10, 25, 50, 100, 200, 500, 1000]}
          paginationMode="server"
          rowCount={data?.data?.result?.count ?? 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          columns={[
            {
              field: "perId",
              headerName: "رقم الهوية",
              width: 200,
              headerAlign: "center",
              align: "center",
            },

            {
              field: "fullName",
              headerName: "الاسم",
              width: 200,
            },

            {
              field: "perFamno",
              headerName: "رقم العائلة",
              width: 200,
            },
            {
              field: "PCNO",
              headerName: "رقم مركز ",
              width: 200,
            },
          ]}
        />
      </Box>
    </Paper>
  );
};
