
import { GetAttendancesResponse } from "../types/get-attendances-response.type";
import { axiosClient } from "../../../lib/axios";



export const getAttendancesAPI = async (params: {
    limit: number,
    offset: number
}) => {
    return axiosClient.get<GetAttendancesResponse>('/v1/attendances?relations[]=referredBy&relations[]=checkedBy&relations[]=person', {
        headers: {
            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlck5hbWUiOiJhZG1pbiIsInJvbGUiOiJzdXBlcmFkbWluIiwiaWF0IjoxNjkyNzk4OTI2LCJleHAiOjE2OTM0MDM3MjZ9.bPrQkxdCUTEDc4MAS13X0mmYxaMvClByNNPKlDE-guQ`
        },
        params: {
            limit: params.limit,
            offset: params.offset
        }
    });
}


