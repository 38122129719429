import { Container, Box, Typography, TextField, Button } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { signUpSchema } from "./signup-schema";
import { useMutation } from "@tanstack/react-query";
import { signUpUser } from "./signup.api";
import toast, { Toaster } from "react-hot-toast";

type RegistrationForm = {
  username: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  role: "ADMIN" | "USER";
};

export const SignUp = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { mutateAsync, isSuccess } = useMutation(signUpUser);
  const urlParams = new URLSearchParams(window.location.search);

  // Get the 'token' parameter from the URL
  const token = urlParams.get("token")??"";

  // const queryParams = new URLSearchParams(location.search);

  // const token = queryParams.get("url");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationForm>({
    defaultValues: {
      role: "USER",
    },
    resolver: zodResolver(signUpSchema),
  });

  function onSubmit(data: RegistrationForm) {
    console.log(data);
    toast.promise(
      mutateAsync({
        ...data,
        token,
      }),
      {
        loading: "جاري الإنضمام...",
        success: "تم الإنضمام بنجاح",
        error: (error) => {
          if (error.response.data.message === "Token not found") {
            return "صلاحية الرابط انتهت";
          }
          return ""
        },
      }
    );
  }


  useEffect(() => {
    if (token) {
      // axios.post("/v1/auth/");
    }

    return () => {
      // second
    };
  }, [token]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
    }
  }, [isSuccess]);

  return (
    <Container component="main" maxWidth="xs">
      <Toaster position="top-center" reverseOrder={false} />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          تسجيل حساب جديد
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, (errors) => {
            console.log(errors);
          })}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="اسم المستخدم"
            {...register("username", { required: true })}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="رقم الهاتف"
            type="text"
            id="phoneNumber"
            autoComplete="phoneNumber"
            {...register("phoneNumber", { required: true })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="كلمة المرور"
            type="password"
            id="password"
            {...register("password", { required: true })}
            error={!!errors.password}
            autoComplete="current-password"
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="تأكيد كلمة المرور"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
            error={!!errors.confirmPassword}
            {...register("confirmPassword", { required: true })}
          />
          {errors.confirmPassword && (
            <Typography variant="h6" color="red">
              {errors.confirmPassword.message}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            تسجيل
          </Button>
          {/* {errorMessage && (
            <Box sx={{ color: "red", marginTop: "10px" }}>{errorMessage}</Box>
          )} */}
        </Box>
      </Box>
    </Container>
  );
};
