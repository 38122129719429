import { ReactNode, useEffect } from "react";
import "./main-layout.scss";
import Sidebar from "../components/navbar/Sidebar";
import TopNav from "../components/navbar/top-nav";
import { Outlet } from "react-router-dom";

const MainLayout = ({ children }: { children?: ReactNode }) => {
  useEffect(() => {
    document.body.classList.add("sidebar-open");

    return () => {
      document.body.classList.remove("sidebar-open");
    };
  }, []);

  const toggleSidebar = () => {
    document.body.classList.toggle("sidebar-open");
  };

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="main__content">
          <TopNav toggleSidebar={toggleSidebar} />
          <Outlet />
          {/* {children} */}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
