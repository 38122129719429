// Login page in react typescript that accept only username and password
// and send them to the server and get the response and show it to the user
// if the user is authenticated then redirect to the home page
// if the user is not authenticated then show the error message to the user
// and if the user is authenticated then save the token in the local storage

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { axiosClient } from "../../lib/axios";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, LoginResponse } from "../../context/AuthContext";

export function LoginPage() {
  let navigate = useNavigate();
  const authContext = useContext(AuthContext);
  

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  const handleLogin = async (event:any) => {
    event.preventDefault();
    try {
      // Simulate sending credentials to the server and getting a response
      // Replace this with actual server communication in your application
      axiosClient
        .post<LoginResponse>("/auth/", {
          userName: username,
          password: password,
        })
        .then((res) => {
          console.log(res.data);
          setAuthenticated(true);
          if(authContext?.onAfterLoginHandler){

            authContext?.onAfterLoginHandler!(res.data);
          }
          //   localStorage.setItem("token", data.token); // Save token to local storage
        })
        .catch((err) => {
          setErrorMessage("الرجاء التأكد من اسم المستخدم وكلمة المرور");
          console.log(err);
        });
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("An error occurred during login.");
    }
  };

  if (authenticated) {
    // Redirect to home page
    // You can use React Router or any navigation library for this
    // Replace the following line with your actual navigation logic
    navigate("/users");
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          تسجيل الدخول
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="اسم المستخدم"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="كلمة المرور"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            تسجيل الدخول
          </Button>
          {errorMessage && (
            <Box sx={{ color: "red", marginTop: "10px" }}>{errorMessage}</Box>
          )}
        </Box>
      </Box>
    </Container>
  );
}
