import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

const sidebarNav = [
  {
    link: "/",
    section: "dashboard",
    icon: (
      <DashboardOutlinedIcon
        sx={{ marginBottom: "-4px", marginRight: "-5px" }}
      />
    ),
    text: "الرئيسية فارغة",
  },
  {
    link: "/users",
    section: "users",
    icon: (
      <PeopleAltOutlinedIcon
        sx={{ marginBottom: "-4px", marginRight: "-5px" }}
      />
    ),
    text: "المستخدمين",
  },
  
  {
    link: "/attendances",
    section: "attendances",
    icon: (
      <PersonOutlineOutlinedIcon
        sx={{ marginBottom: "-4px", marginRight: "-5px" }}
      />
    ),
    text: "المسجلين",
  },

 
];

export default sidebarNav;