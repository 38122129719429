import { useContext } from "react";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import { useRoutes } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export const AppRoutes = () => {
  const { isLogged: isAuthenticated } = useContext(AuthContext);
  const signedIn = isAuthenticated;
  const routes = signedIn ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes]);

  return <>{element}</>;
};
