import { ReactNode, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";


type AuthContextProviderProps = {
  children: ReactNode;
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string>("");
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const onAfterLoginHandler = async (response: LoginResponse) => {
    if (response.status === "success") {
      // setUser(response.result);
      setUser(response.result.user);
      setToken(response.result.token);
      setIsLogged(true);

      await Promise.all([
        localStorage.setItem("token", response.result.token),
        localStorage.setItem("user", JSON.stringify(response.result.user)),
      ]);

      navigate("/");
    }
  };

  const logout = async () => {
    setUser(null);
    setIsLogged(false);
    await Promise.all([
      localStorage.removeItem("token"),
      localStorage.removeItem("user"),
    ]);

    navigate("/login");
  };

  const loadFromLocalStorage = async () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (token && user) {
      setUser(JSON.parse(user));
      setToken(token);
      setIsLogged(true);
    }
  };

  useEffect(() => {
    loadFromLocalStorage().finally(() => setIsInitialized(true));
  }, []);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isLogged,
        onAfterLoginHandler,
        logout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthContext = createContext<Partial<IAuthContext>>({});

export interface IAuthContext {
  user: User | null;
  isLogged: boolean;
  logout: () => void;
  onAfterLoginHandler: (response: LoginResponse) => void;
}

export interface LoginResponse {
  status: string;
  result: UserAuthentication;
}

export interface UserAuthentication {
  token: string;
  user: User;
}

export interface User {
  id: number;
  userName: string;
  password: string;
  phoneNumber: string;
  role: string;
  eventId: any;
  createdAt: string;
  updatedAt: string;
}
