import { RouteObject } from "react-router";
import App from "../App";
import MainLayout from "../layout/main-layout";
import { AttendancesPage } from "../features/attendances/attendances-page";


export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout  />,
    children:[
      {
        path: "/users/", element: <App /> ,
      },
      {
        path:'/attendances/', element: <AttendancesPage /> ,
      }
    ]
  },
];
