import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMutation } from "@tanstack/react-query";
import { createToken } from "./api/create-token.api";
import toast, { Toaster } from "react-hot-toast";
import { UserPage } from "./features/users/user-page";

function App() {
  const [openModal, setOpenModal] = useState(false);

  const [role, setRole] = useState("user");

  const {
    data,
    isSuccess,
    isError,
    isIdle,
    isLoading,
    mutateAsync: createNewToken,
  } = useMutation(createToken);

  const generateLink = () => {
    createNewToken(role);
  };

  useEffect(() => {
    if (isSuccess) {
      console.log(data);
    }
  }, [data, isSuccess]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography></Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            اضافة مستخدم{" "}
          </Button>
        </Box>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          انشاء رابط
        </DialogTitle>
        <DialogContent>
          <br />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Select
                margin="none"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="user">مشرف</MenuItem>
                <MenuItem value="admin">ادمن</MenuItem>
              </Select>
              <FormLabel id="demo-row-radio-buttons-group-label">
                : دور المستخدم
              </FormLabel>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                fullWidth={false}
                sx={{
                  width: "100px",
                }}
                size="medium"
                onClick={() => {
                  generateLink();
                }}
              >
                انشاء رابط
              </Button>
            </Box>
          </Box>
          <br />
          {!isIdle && isLoading && (
            <Skeleton variant="rectangular" width="100%" height={118} />
          )}
          {data && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth={true}
                disabled
                value={`${process.env.REACT_APP_USER_URL}/signup?token=${data?.data.result.token}`}
              />

              <CopyToClipboard
                text={`${process.env.REACT_APP_USER_URL}/signup?token=${data?.data.result.token}`}
                options={{
                  format: "text/plain",
                  message: "Copy to clipboard with button",
                }}
                onCopy={(text: string, result: boolean) => {
                  console.log(text);
                  if (result) {
                    toast.success("تم النسخ بنجاح");
                    console.log(result);
                  } else {
                    toast.error("حدث خطأ ما");
                  }
                }}
              >
                <IconButton aria-label="copy">
                  <ContentCopyIcon />
                </IconButton>
              </CopyToClipboard>
            </Box>
          )}
        </DialogContent>
        <Toaster
          containerStyle={{
            zIndex: "999999 !important",
          }}
          toastOptions={{
            className: "react-hot-toast",
            style: {
              zIndex: "999999 !important",
            },
          }}
          position="bottom-center"
          reverseOrder={false}
        />
      </Dialog>
      <UserPage />
    </Box>
  );
}

export default App;
