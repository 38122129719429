import { axiosClient } from "../../lib/axios";



export const signUpUser = async (data: {
    username: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    role: "ADMIN" | "USER";
    token: string;
}) => {
    console.log(data)
    const response = await axiosClient.post('/auth/signup', {
        userName: data.username,
        password: data.password,
        role: data.role.toLocaleLowerCase(),
        phoneNumber: `+964${data.phoneNumber.substring(1)}`,
        eventId: 1
    }, {
        params: {
            token: data.token
        }
    })
    return response

}