import { GetUsersResponse } from "../types/get-users-response";
import { axiosClient } from "../../../lib/axios";


export const getUsersAPI = async () => {
    return axiosClient.get<GetUsersResponse>('/v1/users',{
        headers: {
            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlck5hbWUiOiJhZG1pbiIsInJvbGUiOiJzdXBlcmFkbWluIiwiaWF0IjoxNjkyNzk4OTI2LCJleHAiOjE2OTM0MDM3MjZ9.bPrQkxdCUTEDc4MAS13X0mmYxaMvClByNNPKlDE-guQ`
        }
    });
}


