import * as zod from 'zod'

type RegistrationForm = {
    username: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    role: "ADMIN" | "USER";
  };
  

export const signUpSchema = zod.object({
    username: zod.string().min(3).max(20),
    password: zod.string().min(6).max(24),
    confirmPassword: zod.string().min(6).max(24),
    phoneNumber: zod.string().min(11).max(11),
    role: zod.enum(["ADMIN", "USER"]),
}).superRefine(({ password, confirmPassword }, ctx) => {
    if (password !== confirmPassword) {
        ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: "الرقم السري غير متطابق",
            path: ["confirmPassword"],
        });
    }
});
export type SignUpSchema = zod.infer<typeof signUpSchema>;
    
