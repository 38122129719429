import Axios from 'axios';
export const apiClientController = new AbortController();
export const axiosClient = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    signal: apiClientController.signal,

});


axiosClient.interceptors.request.use(
    (config) => {
      const accessToken: string | null = localStorage.getItem("token");
  
      if (accessToken && config.headers) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  