import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./sidebar.scss";
import sidebarNav from "./side-bar";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const authContext = useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex(
      (item: { section: string }) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const logout = () => {
    navigate("/login");
    if (authContext?.logout) {
      authContext.logout();
    }
    localStorage.clear();
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo" style={{ width: "100%" }}>
        <h2>E-System</h2>
      </div>
      <div className="sidebar__menu">
        <div className="sidebar__menu__list">
          {sidebarNav.map((nav, index) => {
            return (
              <Link
                to={nav.link}
                key={`nav-${index}`}
                className={`sidebar__menu__list__item ${
                  activeIndex === index && "active"
                }`}
              >
                <div className="sidebar__menu__list__item__icon">
                  {nav.icon}
                </div>
                <div className="sidebar__menu__list__item__txt">{nav.text}</div>
              </Link>
            );
          })}
        </div>
        <div onClick={() => logout()} className="logout">
          <div className="logout__txt">Logout</div>
          <div className="logout__icon">
            <i
              style={{ color: "#FD523E" }}
              className="bx bx-log-out-circle"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
