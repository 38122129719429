import { useMutation } from "@tanstack/react-query";
import { getUsersAPI } from "./api/get-users";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

export const UserPage = () => {
  const { mutateAsync, data } = useMutation(getUsersAPI);

  useEffect(() => {
    toast.promise(mutateAsync(), {
      loading: "جاري تحميل البيانات",
      success: "تم تحميل البيانات بنجاح",
      error: "حدث خطأ اثناء تحميل البيانات",
    });
    // mutateAsync();
  }, []);

  return (
    <Box>
      <Toaster
        containerClassName="toaster-wrapper"
        position="top-center"
        containerStyle={{
          zIndex: "999999 !important",
        }}
        toastOptions={{
          className: "react-hot-toast",
        }}
      />
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={data?.data?.result?.list ?? []}
          disableColumnSelector={true}
          columns={[
            {
              field: "id",
              headerName: "ID",
              width: 100,
            },
            {
              field: "userName",
              headerName: "الاسم",
              width: 200,
            },
            {
              field: "phoneNumber",
              headerName: "رقم الهاتف",
              width: 200,
            },
            {
              field: "role",
              headerName: "المنصب",
              width: 100,
              valueFormatter: (params: GridValueFormatterParams<any>) => {
                return params.value === "user" ? "مسؤول" : "ادمن";
              },
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
